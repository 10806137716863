import React from "react";
import Link from "../misc/link";

const IntegrationDestinationDocumentation = ({ integration, destination }) => {
    let microverseUrl = "";

    if (integration.microversename) {
        if (destination.name === "Amazon Redshift") {
            microverseUrl = `https://${integration.microversename}.toredshift.com`;
        } else if (destination.name === "PostgreSQL") {
            microverseUrl = `https://${integration.microversename}.topostgres.com`;
        } else if (destination.name === "Google BigQuery") {
            microverseUrl = `https://${integration.microversename}.tobigquery.com`;
        } else if (destination.name === "Snowflake") {
            microverseUrl = `https://${integration.microversename}.tosnowflake.com`;
        } else if (destination.name === "Panoply") {
            microverseUrl = `https://${integration.microversename}.topanoply.com`;
        }
    } else {
        if (destination.name === "Amazon Redshift") {
            microverseUrl = `https://${integration.url}.toredshift.com`;
        } else if (destination.name === "PostgreSQL") {
            microverseUrl = `https://${integration.url}.topostgres.com`;
        } else if (destination.name === "Google BigQuery") {
            microverseUrl = `https://${integration.url}.tobigquery.com`;
        } else if (destination.name === "Snowflake") {
            microverseUrl = `https://${integration.url}.tosnowflake.com`;
        } else if (destination.name === "Panoply") {
            microverseUrl = `https://${integration.url}.topanoply.com`;
        }
    }

    return (
        <section className="st-section">
            <div className="st-feature--thin">
                <h2 className="st-heading--2">Learn more about syncing {integration.name} data</h2>
                <p className="st-subheading"></p>
                {integration.documentation_link ? (
                    <div className="sample">
                        <h3 className="st-heading--4">Documentation</h3>
                        <p>Detailed documentation on how to start syncing {integration.name} data.</p>
                        <p>
                            <Link
                                to={`/docs${integration.setup_documentation_link}`}
                                className={`st-button-plain--alt`}
                            >
                                {integration.name} Documentation<span className="arrow"></span>
                            </Link>
                        </p>
                    </div>
                ) : (
                    <div className="sample">
                        <h3 className="st-heading--4">Community Support</h3>
                        <p>
                            The Stitch {integration.name} integration is maintained by the open source Singer community.
                        </p>
                        <p>
                            <Link to={integration.gitHubRepository} className={`st-button-plain--alt`}>
                                View the Repo<span className="arrow"></span>
                            </Link>
                        </p>
                    </div>
                )}
                {destination.name !== "Amazon S3" && integration.generate_microverse_pages !== false && (
                    <div className="sample">
                        <h3 className="st-heading--4">Manual Instructions</h3>
                        <p>
                            How to extract data from {integration.name} and load it to {destination.name} manually.
                        </p>
                        <p>
                            <Link to={microverseUrl} className={`st-button-plain--alt`}>
                                {integration.name} and load it to {destination.name} manually.
                                <span className="arrow"></span>
                            </Link>
                        </p>
                    </div>
                )}
            </div>
        </section>
    );
};

export default IntegrationDestinationDocumentation;
