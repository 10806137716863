import React from "react";
import Link from "../misc/link";
import assetAnalysisTools from "../../assets/images/illustrations/asset-analysis-tools.svg";

const IntegrationsToolsCallout = ({ containerClasses }) => {
    return (
        <section className={containerClasses}>
            <div className="content">
                <div className="st-callout">
                    <h2 className="st-heading--2">All your data, where you need it</h2>
                    <div className="callout-content">
                        <p className="st-subheading">
                            Give your analysts, data scientists, and other team members the freedom to use the analytics
                            tools of their choice.
                        </p>
                        <p>
                            <Link to="/integrations/analysis-tools" className="st-button-cta">
                                See all analysis tools <span className="arrow"></span>
                            </Link>
                        </p>
                    </div>
                    <div className="callout-decor">
                        <img src={assetAnalysisTools} alt="Analysis Tools Asset" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IntegrationsToolsCallout;
