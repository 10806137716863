import React from "react";
import { graphql } from "gatsby";
import SectionSeparator from "../components/layout/section-separator";
import TestimonialSection from "../components/layout/testimonial-section";
import IntegrationFilterSection from "../components/integrations/integrations-filter-section";
import WhyChooseStitch from "../components/layout/why-choose-stitch";
import IntegrationsToolsCallout from "../components/integrations/integrations-tools-callout";
import IntegrationsDestinationsCallout from "../components/integrations/integrations-destinations-callout";
import IntegrationCategory from "../components/integrations/integration-category";
import IntegrationEndpointsGrid from "../components/integrations/integration-endpoints-grid";
import IntegrationDestinationDocumentation from "../components/integrations/integration-destination-documentation";
import { useVideoSelector } from "../hooks/VideoSelector";
import VideoContainer from "../components/misc/video-container";
import CTASection from "../components/misc/cta-section";
import AnalyticsBlocks from "../components/integrations/analytics-blocks";

import Layout from "../components/layout/layout";
import Seo from "../components/misc/Seo/seo";

const IntegrationvsDestination = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;
    const integration = data.integrationItem;
    const destination = data.destinationItem;
    const bodyClasses = `${pageContext.type} integrations integrations_${integration.url} integrations_${integration.url}_${destination.url}`;

    let pageTitle = `${integration.name} to ${destination.name} in minutes--for free`;
    let pageDescription = `Join your ${integration.name} and ${destination.name} data in minutes without the headache of writing and maintaining ETL scripts.`;
    const pageHeading = `${integration.name} to ${destination.name} in minutes`;
    let pageSubHeading = `${integration.name} ${integration.short_description}. The Stitch ${integration.name} integration will <mark>ETL your ${integration.name} data to ${destination.name} in minutes</mark> and keep it up to date without the headache of writing and maintaining ETL scripts.`;

    if (integration.premium_integration) {
        pageTitle = `${integration.name} to ${destination.name} in minutes`;
        pageDescription = `${integration.name} to ${destination.name} in minutes without the headache of writing and maintaining ETL scripts. Extract ${integration.name} data and load into a ${destination.name} data warehouse.`;
    }

    if (integration.name === "PostgreSQL" && destination.name === "PostgreSQL") {
        pageSubHeading = `The Stitch PostgreSQL integration enables you to ETL data from one PostgreSQL database to another PostgreSQL you'd like to use as a data warehouse. Your data will be in your PostgresQL data warehouse in minutes and Stitch will keep it up to date without the headache of writing and maintaining ETL scripts.`;
    } else if (integration.status === "Coming Soon") {
        pageSubHeading = `The Stitch ${integration.name} integration is currently in development. Enter your information below, and we’ll notify you the moment it enters beta.`;
    } else if (destination.status === "Coming Soon") {
        pageSubHeading = `Data warehouse support for ${destination.name} is in development. Enter your information below, and we'll notify you the moment it enters beta.`;
    } else {
        if (integration.integration_category.name === "Databases") {
            pageSubHeading = `The Stitch ${integration.name} integration will ETL your ${integration.name} to ${destination.name} in minutes and keep it up to date without the headache of writing and maintaining ETL scripts.`;
        }
    }

    const selectedVideo = useVideoSelector("integration-destination", [integration.type_id, destination.type_id]);

    return (
        <Layout
            menus={menus}
            headerType={pageContext.type}
            headerItems={[integration, destination]}
            pageHeading={pageHeading}
            pageSubHeading={pageSubHeading}
        >
            <Seo
                type={"Page"}
                title={pageTitle}
                description={pageDescription}
                slug={`/integrations/${integration.url}/${destination.url}`}
                bodyClass={bodyClasses}
            />
            {integration.status !== "Coming Soon" && (
                <>
                    <IntegrationCategory integration={integration} />
                    {integration.endpoints && <IntegrationEndpointsGrid integration={integration} />}
                    <IntegrationDestinationDocumentation integration={integration} destination={destination} />
                    <SectionSeparator />
                    <AnalyticsBlocks integration={integration} />
                    <CTASection
                        heading={`Start replicating your ${integration.name} data`}
                        subheading={`Select your integrations, choose your warehouse, and enjoy Stitch free for 14 days.`}
                        containerClasses={`st-section--lowlight`}
                        dualCTA={true}
                    />
                    <IntegrationsDestinationsCallout destination={destination} />
                    {selectedVideo && (
                        <VideoContainer
                            videoEmbedCode={selectedVideo[0].embedCode.embedCode}
                            caption={selectedVideo[0].caption.caption}
                        />
                    )}

                    <SectionSeparator />
                    <IntegrationsToolsCallout containerClasses={`st-section`} />
                </>
            )}
            {destination.customer ? (
                <TestimonialSection
                    quote={destination.customer[0].quote.quote}
                    author={destination.customer[0].quote_name}
                    position={`${destination.customer[0].quote_title}, ${destination.customer[0].company_name}`}
                />
            ) : (
                <TestimonialSection
                    quote={`Stitch's interface is sleek and efficient. We only know it's running when it sends us alerts; otherwise, it does its job without bother.`}
                    author={`Amaury Dumoulin`}
                    position={`Data Lead, Qonto`}
                />
            )}
            <SectionSeparator />
            <WhyChooseStitch />
            <IntegrationFilterSection
                heading={`Connect to your ecosystem of data sources`}
                paragraph={`Stitch integrates with leading databases and SaaS products. No API maintenance, ever, while you maintain full control over replication behavior.`}
            />
        </Layout>
    );
};

export const query = graphql`
    query($integration: String!, $destination: String!) {
        integrationItem: contentfulIntegration(url: { eq: $integration }) {
            type_id
            contentful_id
            name
            url
            status
            microversename
            premium_integration
            short_description
            certified
            integration_category {
                name
            }
            cropped_logo {
                file {
                    url
                }
            }
            square_logo {
                file {
                    url
                }
            }
            logo {
                file {
                    url
                }
            }
            documentation_link
            setup_documentation_link
            gitHubRepository
            endpoints {
                integration_name
                schema_name
                description {
                    description
                }
            }
            generate_microverse_pages
            analytics_block {
                analytics_block_title
                analytics_package
                block_author
                block_published_date
                contentful_id
                description {
                    description
                }
                integrationName
                link_to_source
                code_snippet {
                    code_snippet
                }
            }
        }
        destinationItem: contentfulDataEndpoint(url: { eq: $destination }) {
            status
            type_id
            contentful_id
            name
            url
            customer {
                company_name
                quote_name
                quote_title
                quote {
                    quote
                }
            }
            logo {
                file {
                    url
                }
            }
            square_logo {
                file {
                    url
                }
            }
        }
    }
`;

export default IntegrationvsDestination;
