/* Text starts with "From <x> to <y>" */
/* File used: /src/components/integrations/integration-analysis-tool-faster-decisions-section.js */
export const internalLinksMap = {
    activecampaign: "https://www.stitchdata.com/integrations/outreach/activecampaign/",
    adroll_old: "https://www.stitchdata.com/integrations/shiphero/adroll_old/",
    "amazon-aurora": "https://www.stitchdata.com/integrations/amazon-aurora/snowflake/",
    "amazon-aurora-postgresql": "https://www.stitchdata.com/integrations/amazon-aurora/snowflake/",
    "amazon-rds-mysql": "https://www.stitchdata.com/integrations/surveymonkey/amazon-rds-mysql/",
    "amazon-rds-oracle": "https://www.stitchdata.com/integrations/amazon-rds-oracle/powerbi/",
    "amazon-rds-sqlserver": "https://www.stitchdata.com/integrations/salesforce/amazon-rds-sqlserver/",
    appsflyer: "https://www.stitchdata.com/integrations/appsflyer/amazon-s3/",
    "doubleclick-campaign-manager": "https://www.stitchdata.com/integrations/salesforce/doubleclick-campaign-manager/",
    chargebee: "https://www.stitchdata.com/integrations/chargebee/salesforce-marketing-cloud/",
    contentful: "https://www.stitchdata.com/integrations/zapier/contentful/",
    eloqua: "https://www.stitchdata.com/integrations/zapier/eloqua/",
    formkeep: "https://www.stitchdata.com/docs/integrations/webhooks/formkeep",
    fullstory: "https://www.stitchdata.com/integrations/fullstory/amazon-redshift/",
    "google-analytics-360": "https://www.stitchdata.com/docs/integrations/saas/google-analytics-360",
    "google-cloud-sql-archive": "https://www.stitchdata.com/integrations/salesforce/google-cloud-sql/",
    "google-cloud-sql": "https://www.stitchdata.com/integrations/salesforce/google-cloud-sql/",
    "google-cloud-sql-postgresql": "https://www.stitchdata.com/integrations/salesforce/google-cloud-sql-postgresql/",
    "harvest-forecast": "https://www.stitchdata.com/integrations/asana/harvest-forecast/",
    heroku: "https://www.stitchdata.com/integrations/heroku/snowflake/",
    db2: "https://www.stitchdata.com/integrations/talend/db2-database/",
    "import-api": "https://www.stitchdata.com/docs/developers/import-api/",
    invoiced: "https://www.stitchdata.com/integrations/invoiced/amazon-sagemaker/",
    klaviyo: "https://www.stitchdata.com/docs/integrations/saas/klaviyo",
    liveperson: "https://www.stitchdata.com/integrations/liveperson/amazon-s3/",
    lookml: "https://www.stitchdata.com/integrations/lookml/powerbi/",
    mailjet: "https://www.stitchdata.com/integrations/zapier/mailjet/",
    mailshake: "https://www.stitchdata.com/integrations/mailshake/hp-postgres/",
    mambu: "https://www.stitchdata.com/integrations/mambu/powerbi/",
    "marketo-bulk": "https://www.stitchdata.com/integrations/amplitude/marketo-bulk/",
    "sql-server": "https://www.stitchdata.com/docs/integrations/databases/microsoft-sql-server/v1",
    "netsuite-suite-analytics": "https://www.stitchdata.com/integrations/salesforce/netsuite-suite-analytics/",
    bronto: "https://www.stitchdata.com/integrations/netsuite/bronto/",
    outbrain: "https://www.stitchdata.com/integrations/google-ads/outbrain/",
    pardot: "https://www.stitchdata.com/docs/integrations/saas/pardot",
    pendo: "https://www.stitchdata.com/integrations/segment/pendo/",
    pipedrive: "https://www.stitchdata.com/integrations/pipedrive/r/",
    "platform-purple": "https://www.stitchdata.com/integrations/zendesk/platform-purple/",
    "quick-base": "https://www.stitchdata.com/integrations/xero/quick-base/",
    recurly: "https://www.stitchdata.com/integrations/iterable/recurly/",
    "referral-saasquatch": "https://www.stitchdata.com/integrations/chargebee/referral-saasquatch/",
    responsys: "https://www.stitchdata.com/integrations/responsys/platform.mixpanel/",
    revinate: "https://www.stitchdata.com/integrations/revinate/spotfire-tibco/",
    ringcentral: "https://www.stitchdata.com/integrations/ringcentral/quick-base/",
    sailthru: "https://www.stitchdata.com/integrations/sailthru/zapier/",
    selligent: "https://www.stitchdata.com/integrations/selligent/salesforce-marketing-cloud/",
    shiphero: "https://www.stitchdata.com/integrations/shiphero/zapier/",
    shippo: "https://www.stitchdata.com/integrations/shiphero/shippo/",
    "snapchat-ads": "https://www.stitchdata.com/docs/integrations/saas/snapchat-ads",
    sparkpost: "https://www.stitchdata.com/docs/integrations/webhooks/sparkpost",
    surveymonkey: "https://www.stitchdata.com/integrations/surveymonkey/google-data-studio/",
    taboola: "https://www.stitchdata.com/integrations/tiktok-ads/taboola/",
    typeform: "https://www.stitchdata.com/integrations/contentful/typeform/",
    uservoice: "https://www.stitchdata.com/integrations/zapier/uservoice/",
    yotpo: "https://www.stitchdata.com/integrations/import-api/yotpo/",
    zuora: "https://www.stitchdata.com/integrations/amplitude/zuora/",
};

/* Text starts with "Analyze. From there it's easy to connect" */
/* File used: /src/components/integrations/integration-analysis-tool-faster-decisions-section.js */
export const internalLinksMapTwo = {
    shiny: "https://www.stitchdata.com/analysis-tools/shiny",
    sisense: "https://www.stitchdata.com/analysis-tools/sisense",
    tableau: "https://www.stitchdata.com/analysis-tools/tableau",
    powerbi: "https://www.stitchdata.com/analysis-tools/powerbi",
    "google-data-studio": "https://www.stitchdata.com/analysis-tools/google-data-studio",
    "amazon-quicksight": "https://www.stitchdata.com/analysis-tools/amazon-quicksight",
    mode: "https://www.stitchdata.com/analysis-tools/mode",
    chartio: "https://www.stitchdata.com/analysis-tools/chartio",
    metabase: "https://www.stitchdata.com/analysis-tools/metabase",
    alteryx: "https://www.stitchdata.com/analysis-tools/alteryx",
    incorta: "https://www.stitchdata.com/analysis-tools/incorta",
    "afs-g2": "https://www.stitchdata.com/analysis-tools/afs-g2",
    intellifront: "https://www.stitchdata.com/analysis-tools/intellifront",
    "targit-decision-suite": "https://www.stitchdata.com/analysis-tools/targit-decision-suite",
    jreport: "https://www.stitchdata.com/analysis-tools/jreport",
    numetric: "https://www.stitchdata.com/analysis-tools/numetric",
    prognoz: "https://www.stitchdata.com/analysis-tools/prognoz",
    izenda: "https://www.stitchdata.com/analysis-tools/izenda",
    "metric-insights": "https://www.stitchdata.com/analysis-tools/metric-insights",
    veera: "https://www.stitchdata.com/analysis-tools/veera",
    halo: "https://www.stitchdata.com/analysis-tools/halo",
    exago: "https://www.stitchdata.com/analysis-tools/exago",
    inetsoft: "https://www.stitchdata.com/analysis-tools/inetsoft",
    excel: "https://www.stitchdata.com/analysis-tools/excel",
    lityxiq: "https://www.stitchdata.com/analysis-tools/lityxiq",
    knowi: "https://www.stitchdata.com/analysis-tools/knowi",
    kato: "https://www.stitchdata.com/analysis-tools/kato",
    intellicus: "https://www.stitchdata.com/analysis-tools/intellicus",
    "cloud-datalab": "https://www.stitchdata.com/analysis-tools/cloud-datalab",
    datameer: "https://www.stitchdata.com/analysis-tools/datameer",
    arcadia: "https://www.stitchdata.com/analysis-tools/arcadia",
    "toucan-toco": "https://www.stitchdata.com/analysis-tools/toucan-toco",
    dataform: "https://www.stitchdata.com/analysis-tools/dataform",
    thoughtspot: "https://www.stitchdata.com/analysis-tools/thoughtspot",
    exploratory: "https://www.stitchdata.com/analysis-tools/exploratory",
    "sas-business-intelligence": "https://www.stitchdata.com/analysis-tools/sas-business-intelligence",
    datapine: "https://www.stitchdata.com/analysis-tools/datapine",
    "trevor-io": "https://www.stitchdata.com/analysis-tools/trevor-io",
    redash: "https://www.stitchdata.com/analysis-tools/redash",
    python: "https://www.stitchdata.com/analysis-tools/python",
    jupyter: "https://www.stitchdata.com/analysis-tools/jupyter",
    beaker: "https://www.stitchdata.com/analysis-tools/beaker",
    datawrapper: "https://www.stitchdata.com/analysis-tools/datawrapper",
    qlik: "https://www.stitchdata.com/analysis-tools/qlik",
    clicdata: "https://www.stitchdata.com/analysis-tools/clicdata",
    pentaho: "https://www.stitchdata.com/analysis-tools/pentaho",
    highcharts: "https://www.stitchdata.com/analysis-tools/highcharts",
    "dundas-bi": "https://www.stitchdata.com/analysis-tools/dundas-bi",
    r: "https://www.stitchdata.com/analysis-tools/r",
    cluvio: "https://www.stitchdata.com/analysis-tools/cluvio",
    indicative: "https://www.stitchdata.com/analysis-tools/indicative",
    plotly: "https://www.stitchdata.com/analysis-tools/plotly",
    gooddata: "https://www.stitchdata.com/analysis-tools/gooddata",
    zoomdata: "https://www.stitchdata.com/analysis-tools/zoomdata",
    fusioncharts: "https://www.stitchdata.com/analysis-tools/fusioncharts",
    "magento-bi": "https://www.stitchdata.com/analysis-tools/magento-bi",
    bime: "https://www.stitchdata.com/analysis-tools/bime",
    domo: "https://www.stitchdata.com/analysis-tools/domo",
    microstrategy: "https://www.stitchdata.com/analysis-tools/microstrategy",
    klipfolio: "https://www.stitchdata.com/analysis-tools/klipfolio",
    jackdb: "https://www.stitchdata.com/analysis-tools/jackdb",
    grow: "https://www.stitchdata.com/analysis-tools/grow",
    carto: "https://www.stitchdata.com/analysis-tools/carto",
    statsbot: "https://www.stitchdata.com/analysis-tools/statsbot",
    superset: "https://www.stitchdata.com/analysis-tools/superset",
    zeppelin: "https://www.stitchdata.com/analysis-tools/zeppelin",
    paxata: "https://www.stitchdata.com/analysis-tools/paxata",
    tamr: "https://www.stitchdata.com/analysis-tools/tamr",
    trifacta: "https://www.stitchdata.com/analysis-tools/trifacta",
    holistics: "https://www.stitchdata.com/analysis-tools/holistics",
    keshif: "https://www.stitchdata.com/analysis-tools/keshif",
    rockdaisy: "https://www.stitchdata.com/analysis-tools/rockdaisy",
    birst: "https://www.stitchdata.com/analysis-tools/birst",
    yellowfin: "https://www.stitchdata.com/analysis-tools/yellowfin",
    knime: "https://www.stitchdata.com/analysis-tools/knime",
    ubiq: "https://www.stitchdata.com/analysis-tools/ubiq",
    slemma: "https://www.stitchdata.com/analysis-tools/slemma",
    reportplus: "https://www.stitchdata.com/analysis-tools/reportplus",
    "sap-lumira": "https://www.stitchdata.com/analysis-tools/sap-lumira",
    razorsql: "https://www.stitchdata.com/analysis-tools/razorsql",
    numeracy: "https://www.stitchdata.com/analysis-tools/numeracy",
    seekwell: "https://www.stitchdata.com/analysis-tools/seekwell",
    "spotfire-tibco": "https://www.stitchdata.com/analysis-tools/spotfire-tibco",
    rapidminer: "https://www.stitchdata.com/analysis-tools/rapidminer",
    qubole: "https://www.stitchdata.com/analysis-tools/qubole",
    qubit: "https://www.stitchdata.com/analysis-tools/qubit",
    databricks: "https://www.stitchdata.com/analysis-tools/databricks",
    popsql: "https://www.stitchdata.com/analysis-tools/popsql",
    "board-international": "https://www.stitchdata.com/analysis-tools/board-international",
    webfocus: "https://www.stitchdata.com/analysis-tools/webfocus",
    "logi-analytics": "https://www.stitchdata.com/analysis-tools/logi-analytics",
    idashboards: "https://www.stitchdata.com/analysis-tools/idashboards",
    answerrocket: "https://www.stitchdata.com/analysis-tools/answerrocket",
    "ibm-cognos": "https://www.stitchdata.com/analysis-tools/ibm-cognos",
    clearstory: "https://www.stitchdata.com/analysis-tools/clearstory",
    infogram: "https://www.stitchdata.com/analysis-tools/infogram",
    "pyramid-analytics": "https://www.stitchdata.com/analysis-tools/pyramid-analytics",
    "apache-spark": "https://www.stitchdata.com/analysis-tools/apache-spark",
    looker: "https://www.stitchdata.com/analysis-tools/looker",
    "zoho-reports": "https://www.stitchdata.com/analysis-tools/zoho-reports",
    h2o: "https://www.stitchdata.com/analysis-tools/h2o",
    datarobot: "https://www.stitchdata.com/analysis-tools/datarobot",
    "amazon-sagemaker": "https://www.stitchdata.com/analysis-tools/amazon-sagemaker",
    "oracle-business-intelligence": "https://www.stitchdata.com/analysis-tools/oracle-business-intelligence",
};

/* Text starts with "Simplify your <x> migration" */
/* File used: /src/components/integrations/integrations-destinations-callout.js */
export const internalLinksMapThree = {
    "amazon-redshift":
        "https://www.stitchdata.com/docs/destinations/amazon-redshift/connecting-redshift-data-warehouse-to-stitch",
    "azure-sql-data-warehouse":
        "https://www.stitchdata.com/docs/destinations/microsoft-azure-synapse-analytics/reference",
    "databricks-delta": "https://www.stitchdata.com/analysis-tools/databricks/",
    "google-bigquery":
        "https://www.stitchdata.com/docs/destinations/google-bigquery/v2/connecting-google-bigquery-to-stitch",
    "azure-sql-data-warehouse":
        "https://www.stitchdata.com/docs/destinations/microsoft-azure-synapse-analytics/reference",
    mssql: "https://www.stitchdata.com/docs/integrations/databases/microsoft-sql-server/v1",
    panoply: "https://www.stitchdata.com/docs/replication/loading/reference/panoply",
};

// export default internalLinksMap;
